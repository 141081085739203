exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-comparte-js": () => import("./../../../src/pages/comparte.js" /* webpackChunkName: "component---src-pages-comparte-js" */),
  "component---src-pages-estudia-js": () => import("./../../../src/pages/estudia.js" /* webpackChunkName: "component---src-pages-estudia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-profundiza-js": () => import("./../../../src/pages/profundiza.js" /* webpackChunkName: "component---src-pages-profundiza-js" */),
  "component---src-templates-comparte-js": () => import("./../../../src/templates/comparte.js" /* webpackChunkName: "component---src-templates-comparte-js" */),
  "component---src-templates-estudia-js": () => import("./../../../src/templates/estudia.js" /* webpackChunkName: "component---src-templates-estudia-js" */),
  "component---src-templates-home-comunica-js": () => import("./../../../src/templates/homeComunica.js" /* webpackChunkName: "component---src-templates-home-comunica-js" */),
  "component---src-templates-home-dialoga-js": () => import("./../../../src/templates/homeDialoga.js" /* webpackChunkName: "component---src-templates-home-dialoga-js" */),
  "component---src-templates-home-inicio-js": () => import("./../../../src/templates/homeInicio.js" /* webpackChunkName: "component---src-templates-home-inicio-js" */),
  "component---src-templates-perfil-js": () => import("./../../../src/templates/perfil.js" /* webpackChunkName: "component---src-templates-perfil-js" */),
  "component---src-templates-profundiza-js": () => import("./../../../src/templates/profundiza.js" /* webpackChunkName: "component---src-templates-profundiza-js" */)
}


module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.13.1_gatsby-plugin-sharp@4.25.1_gatsby@4.25.8_babel-eslint@10.1.0_esli_jhosplvixpkvjqajzzo4ewbq4m/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@4.25.0_gatsby@4.25.8_babel-eslint@10.1.0_eslint@8.56.0__react-dom@17.0_sv526fkruz3qvlbwhxzz2v3sou/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Escuela Sabática Universitaria","short_name":"ESU","start_url":"/","background_color":"#007f98","theme_color":"#007f98","display":"minimal-ui","icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"661bf8b9d918ecbd2ad730c1638b9a2f"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@5.25.0_babel-plugin-styled-components@2.1.4_@babel+core@7.23._on7idul2xkwlxz2e4srslleyey/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@5.25.0_gatsby@4.25.8_babel-eslint@10.1.0_eslint@8.56.0__react-dom@17.0._qghyozrppippu5axi2mzfplggm/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@4.25.8_babel-eslint@10.1.0_eslint@8.56.0__react-dom@17.0.2_react@17.0.2__react@17.0.2__bn5l6evpu34n2sya4pk2nhj7ym/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
